// import USAFlag from './assets/images/USAFlag.png';
// import { FrenchFlag } from './assets/images/FrenchFlag';
// import FrenchFlag from './assets/flags/fr.png';
// import fiveThousand from "../assets/images/becomeApartner/5000+.svg";
// import multilanguage from "../assets/images/becomeApartner/multilanguage.svg";
// import revenueIcon from "../assets/images/becomeApartner/revenue-icon.svg";

import { useMediaQuery } from "@mui/material";
import RedChats from "../assets/images/advantages/RedChats.svg";
import RedCoin from "../assets/images/advantages/RedCoin.svg";
import RedWalet from "../assets/images/advantages/RedWalet.svg";
import fiveThousand from "../assets/images/becomeApartner/5000+.svg";
import multilanguage from "../assets/images/becomeApartner/multilanguage.svg";
import revenueIcon from "../assets/images/becomeApartner/revenue-icon.svg";
import Calssicslots from "../assets/images/casinoRoyale/calssicslots.svg";
import LiveCasino from "../assets/images/casinoRoyale/livecasino.svg";
import TableGames from "../assets/images/casinoRoyale/TableGames.svg";
import Logo40 from "../assets/images/SliderLogo/airdice.svg";
import Logo1 from "../assets/images/SliderLogo/Amusnet.svg";
import Logo2 from "../assets/images/SliderLogo/Apparat.svg";
import Logo3 from "../assets/images/SliderLogo/AvatarUX.svg";
import Logo4 from "../assets/images/SliderLogo/BetSoft.svg";
import Logo5 from "../assets/images/SliderLogo/Betsygames.svg";
import Logo6 from "../assets/images/SliderLogo/Bgaming.svg";
import Logo7 from "../assets/images/SliderLogo/BigTimeGaming.svg";
import Logo8 from "../assets/images/SliderLogo/Blueprint-Gaming.svg";
import Logo9 from "../assets/images/SliderLogo/Bombay-live.svg";
import Logo10 from "../assets/images/SliderLogo/Booming-Games.svg";
import Logo11 from "../assets/images/SliderLogo/Caleta.svg";
import Logo12 from "../assets/images/SliderLogo/Darwin.svg";
import Logo13 from "../assets/images/SliderLogo/Evolution-Gaming.svg";
import Logo14 from "../assets/images/SliderLogo/Fantasma.svg";
import Logo15 from "../assets/images/SliderLogo/GameArt.svg";
import Logo16 from "../assets/images/SliderLogo/Genii.svg";
import Logo17 from "../assets/images/SliderLogo/Golden-Rock.svg";
import Logo18 from "../assets/images/SliderLogo/Habangero.svg";
import Logo19 from "../assets/images/SliderLogo/Hacksaw-gaming.svg";
import Logo20 from "../assets/images/SliderLogo/Jade-rabbit-studio.svg";
import Logo21 from "../assets/images/SliderLogo/Kalamba-Games.svg";
import Logo22 from "../assets/images/SliderLogo/Mascot.svg";
import Logo23 from "../assets/images/SliderLogo/Nolimit.svg";
import Logo24 from "../assets/images/SliderLogo/OneTouch.svg";
import Logo25 from "../assets/images/SliderLogo/Onlyplay.svg";
import Logo27 from "../assets/images/SliderLogo/Play'nGo.svg";
import Logo26 from "../assets/images/SliderLogo/Playson.svg";
import Logo28 from "../assets/images/SliderLogo/Pocket-Games-Soft.svg";
import Logo29 from "../assets/images/SliderLogo/Pragmatic-Play.svg";
import Logo30 from "../assets/images/SliderLogo/Push-Gaming.svg";
import Logo41 from "../assets/images/SliderLogo/quickspin.svg";
import Logo31 from "../assets/images/SliderLogo/Red-Tiger.svg";
import Logo32 from "../assets/images/SliderLogo/Relax-gaming.svg";
import Logo33 from "../assets/images/SliderLogo/Slotmill.svg";
import Logo34 from "../assets/images/SliderLogo/Swintt.svg";
import Logo35 from "../assets/images/SliderLogo/Tangente-games.svg";
import Logo36 from "../assets/images/SliderLogo/Thunderkick.svg";
import Logo37 from "../assets/images/SliderLogo/TrueLab.svg";
import Logo38 from "../assets/images/SliderLogo/TurboGames.svg";
import Logo39 from "../assets/images/SliderLogo/Wazdan.svg";
import Logo42 from "../assets/images/SliderLogo/zillion-games.svg";

import AFl from "../assets/images/sportsPlay/AFL.svg";
import Horse from "../assets/images/sportsPlay/horse.svg";
import Soccer from "../assets/images/sportsPlay/Soccer.svg";

import ICE_Barcelona_25 from '../assets/images/Events/ICE Barcelona 25.jpg';
import iGB_live from '../assets/images/Events/iGB live.jpeg';
import SBC_Summit from '../assets/images/Events/SBC Summit.jpg';
import SIGMA_Eurasia from '../assets/images/Events/SIGMA Eurasia.jpg';
import SIGMA_Europe_Malta from '../assets/images/Events/SIGMA Europe Malta.jpg';
import ICE from '../assets/logos/ICE.svg';
import iGB_liveL from '../assets/logos/iGB_LIVEL.svg';
import SBC_SummitL from '../assets/logos/SBC_SummitL.svg';
import Sigma_EU from '../assets/logos/Sigma_EU.svg';
import oldPTPLogo from '../assets/images/testimonials/ptptips.svg'
import casinoGuru from '../assets/images/testimonials/casinoguru.svg'
import thegamblerbay from '../assets/images/testimonials/thegamblerbay.svg'
import cyrptogamblers from '../assets/images/testimonials/cyrptogamblers.svg'
import tipgg from '../assets/images/testimonials/tipgg.svg'
import sloteire from '../assets/images/testimonials/sloteire.svg'
import Spicy from '../assets/images/testimonials/Spicy.png'
import casinomentor from '../assets/images/testimonials/casinomentor.webp'
import siteLogo from '../assets/images/testimonials/site-logo-allgems.svg'
import topcasino from "../assets/images/testimonials/topcasino.svg"
import casinoHuner from "../assets/images/testimonials/casino-huner.jpg"
import slotbonusinsider from "../assets/images/testimonials/slotbonusinsider.PNG"
import Slotpicker from "../assets/images/testimonials/Slotpicker.png"

import FreeSpinsGratis from "../assets/images/testimonials/FreeSpinsGratis.png"
import FreeSpinsGames from "../assets/images/testimonials/FreeSpinsGames.png"
import Microgaming from "../assets/images/testimonials/Microgaming.png"
import FreeSpinsMobileCasino from "../assets/images/testimonials/FreeSpinsMobileCasino.png"
import FreeSpinsBet from "../assets/images/testimonials/FreeSpinsBet.png"
import FreeSpinsMicro from "../assets/images/testimonials/FreeSpinsMicro.png"
import FreeSpinsBonus from "../assets/images/testimonials/FreeSpinsBonus.png"
import FreeSpins1 from "../assets/images/testimonials/FreeSpins1.png"
import FreeSpinsInfo from "../assets/images/testimonials/FreeSpinsInfo.png"
import FreeSpinsMicroGaming from "../assets/images/testimonials/FreeSpinsMicroGaming.png"
import FreeSpinsNetentCasino from "../assets/images/testimonials/FreeSpinsNetentCasino.png"
import FreeSpinsNetent from "../assets/images/testimonials/FreeSpinsNetent.png"
import freespinsmicrogamingHat from "../assets/images/testimonials/freespinsmicrogamingHat.png"
import Freispiele from "../assets/images/testimonials/Freispiele.png"
import FreeSpinsGaming from "../assets/images/testimonials/FreeSpinsGaming.png"
import Casinogy from "../assets/images/testimonials/Casinogy.png"
import casinobonuschip from "../assets/images/testimonials/casinobonuschip.png"
import Masters from "../assets/images/testimonials/Masters.png"
import Bonuswanted from "../assets/images/testimonials/bonuswanted.png"
import CasinoGroups from "../assets/images/testimonials/casinoGroups.png"
import Chipy from "../assets/images/testimonials/chipy.png"
import Slotsmate   from "../assets/images/testimonials/slotsmate.png"
import Nonstop     from "../assets/images/testimonials/nonstop.png"
import Casinofreak from "../assets/images/testimonials/casinofreak.png"
import Gamblerslab from "../assets/images/testimonials/gamblerslab.png"
import Jkcp from "../assets/images/testimonials/jkcp.png"
import LuckLandia from "../assets/images/testimonials/lucklandia.jfif"
import kasinopelaajat  from "../assets/images/testimonials/kasinopelaajat.jpg"
import logotipo  from "../assets/images/testimonials/logotipo.png"

export function useWindowSize() {
  const isSmallScreen = useMediaQuery("(max-width: 480px)");
  const isMediumScreen = useMediaQuery("(max-width: 1200px)");
  const xsColumnSize = isSmallScreen ? 4.5 : isMediumScreen ? 5.5 : 12;

  return { isSmallScreen, isMediumScreen, xsColumnSize };
}
export const lngs = [
  {
    name: "English",
    shortName: "EN",
    iconClassName: "flag-icon flag-icon-us me-1",
    // image: './assets/flags/en.png',
  },
  {
    name: "French",
    shortName: "FR",
    iconClassName: "flag-icon flag-icon-fr me-1",
    // image: './assets/flags/fr.png',
  },
];

export const NavBarSections = [
  { name: "BecomeAPartner", id: "become-a-partner" },
  { name: "News", id: "news" },
  { name: "Advantages", id: "advantages" },
  { name: "ContactUs", id: "contact-us" },
];

const SliderArray1 = [
  Logo1,
  Logo2,
  Logo3,
  Logo4,
  Logo5,
  Logo6,
  Logo7,
  Logo8,
  Logo9,
  Logo10,
  Logo11,
  Logo12,
  Logo13,
  Logo14,
  Logo15,
  Logo16,
  Logo17,
  Logo18,
  Logo19,
  Logo20,
  Logo21,

];

const SliderArray2 = [
  Logo22,
  Logo23,
  Logo24,
  Logo25,
  Logo26,
  Logo27,
  Logo28,
  Logo29,
  Logo30,
  Logo31,
  Logo32,
  Logo33,
  Logo34,
  Logo35,
  Logo36,
  Logo37,
  Logo38,
  Logo39,
  Logo40,
  Logo41,
  Logo42,

];

export const SliderList1 = SliderArray1.map((SliderArray1, index) => (
  <img alt="SliderArray1" className="LogoStyle" key={index} src={SliderArray1} />
));

export const SliderList2 = SliderArray2.map((SliderArray2, index) => (
  <img alt="SliderArray2" className="LogoStyle" key={index} src={SliderArray2} />
));

export const SportsPlayTextBoxArray = [
  {
    src: Horse,
    title: "HorseRacingTitle",
    text: "HorseRacingText",
  },
  {
    src: AFl,
    title: "AussieRulesTitle",
    text: "AussieRulesText",
  },
  {
    src: Soccer,
    title: "FootballTitle",
    text: "FootballText",
  },
];

export const AdvantagesTextBoxArray = [
  {
    src: RedChats,
    title: "DetailedStatsTitle",
    text: "DetailedStatsText",
  },
  {
    src: RedCoin,
    title: "LifetimeRevenueTitle",
    text: "LifetimeRevenueText",
  },
  {
    src: RedWalet,
    title: "FastAffiliatePaymentsTitle",
    text: "FastAffiliatePaymentsText",
  },
];

// export const TestimonialsTextBoxArray = [
//   {
//     src: CassinoGrizzly.com,
//     title: "DetailedStatsTitle",
//     text: "DetailedStatsText",
//   },
//   {
//     src: Statsdrone.com,
//     title: "LifetimeRevenueTitle",
//     text: "LifetimeRevenueText",
//   },
//   {
//     src: Norgeascassino.com,
//     title: "FastAffiliatePaymentsTitle",
//     text: "FastAffiliatePaymentsText",
//   },
//   {
//     src: Cassinoservice.org,
//     title: "FastAffiliatePaymentsTitle",
//     text: "FastAffiliatePaymentsText",
//   },
// ];

export const CasinoTextBoxArray = [
  {
    src: Calssicslots,
    title: "CalssicslotsTitle",
    text: "CalssicslotsText",
  },
  {
    src: TableGames,
    title: "TableGamesTitle",
    text: "TableGamesText",
  },
  {
    src: LiveCasino,
    title: "LiveCasinoTitle",
    text: "LiveCasinoText",
  },
];

export const BecomeAPartnerTextBoxArray = [
  {
    src: revenueIcon,
    title: "EarnRevenuetitle",
    text: "EarnRevenuetext",
  },
  {
    src: fiveThousand,
    title: "GameOnTitle",
    text: "GameOntext",
  },
  {
    src: multilanguage,
    title: "GlobalReachTitle",
    text: "GlobalReachtext",
  },
];





export const TermsAndConditionsParagraphArrTitlePoints = [

  {
    Title: "Definitions",
    subTitles: [
      {
        text: " Affiliate: A natural and/or  legal entity who has registered and is accepted by Platform(s) as an      affiliate of the affiliate program in terms of this Agreement.",
      },
      {
        text: " Affiliate Account: The technical reporting and tracking interface set up for the      use by the affiliate to monitor performance, access marketing materials,      access financial reports and configure payment details.",
      },
      {
        text: " Affiliate Network: Acts as an      intermediary between publishers and merchant affiliate programs",
      },
      {
        text: " Affiliate Program: The affiliate program operated by Platform(s) and forms the      subject matter of this Agreement.",
      },

    ],
  },


  {
    Title: "Affiliate Website : a website owned by the Affiliate which sends traffic to      Platform(s)",
    subTitles: [
      {
        text: " Agreement: The contract and its annexes between the Company and the      Affiliate in accordance with the Terms and Conditions of the Affiliate      Program.",
      },
      {
        text: "Company: means New Age N.V,.",
      },
      {
        text: "Content: material provided by the Company for the purposes of this      Agreement, including, without limitation, text links (including Sub-Affiliate      links), banners and other general advertising material.",
      },
      {
        text: " FTD (first time depositor): The successful registration and deposit of a new Player      Account at Platform(s) from affiliate tracking link/links",
      },

      {
        text: " General Terms and      Conditions: Platform(s)’ general terms and conditions",
      },
      {
        text: "Master Affiliate: An approved affiliate is able to operate as a master      affiliate, who recruits new affiliate partners for the Platform(s)      Affiliate Program using the Master Affiliate link from the affiliate      account.",
      },
      {
        text: "Net Gaming Revenue: Monthly Gross Gaming Revenues after the deduction of costs      including, but not limited to, payment processing fees, bonuses, loyalty      rewards, gaming taxes, game supplier costs and chargebacks.",
      },
      {
        text: " New Customers: Customers of Platform(s) who do not yet have a gaming account      and who access Platform(s) via the Tracking Link provided by Platform(s)      and who properly register their account.",
      },


      {
        text: " Real Money Players: A New Customer who has made a real money transfer of at least      equivalent to the minimum deposit into their player account and made at      least one real money bet on Platform(s).",
      },
      {
        text: "Sub-Affiliates: any natural or legal      entity who, after being referred to the Program by Master Affiliate via a      sub affiliate tracking link, enters into this agreement. The referring      Master Affiliate earns commission from the sub affiliates’ players.",
      },


      {
        text: "Tracking Link: An unique link generated in the affiliate account for each      affiliate and granulating on a campaign basis, to track and record New      Customers which the affiliate has brought to Platform(s).",
      },
      {
        text: "Platform – Nabcasino.com",
      },


      {
        text: " Player Account: An affiliate must create a Player Account at Platform(s) they      promote and submit the username to their affiliate account in order to      receive any commissions.",
      },
      {
        text: "Privacy Policy: Platforms privacy policie.",
      },


    ],
  },





];


export const TermsAndConditionsParagraphArrTitle = [
  //1
  {
    Title: "GENERAL SCOPE AND OBJECTIVE",
    subTitles: [
      {
        text: "The Affiliate maintains and operates one or more websites on the internet (collectively referred to as Affiliate Website), and/or refers potential customers to us through other channels.",
      },
      {
        text: "This Agreement governs the terms and conditions which are related to the promotion of Nabcasino by the Affiliate, whereby the Affiliate will be paid a commission as defined in this Agreement depending on the traffic sent to Nabcasino.com and the terms of this Agreement.",
      },
      {
        text: "The definition of the term Net Gaming Revenue is provided above in definitions. In case of an introduction of another product or group of products by us in the future, the Company reserves the right to use an individual definition of the term Net Gaming Revenue for each product.",
      },
    ],
  },
  //2
  {
    Title: "ACCEPTANCE OF AN AFFILIATE",
    subTitles: [
      {
        text: " The Company reserves the right to refuse any Affiliate Program application in its sole and absolute discretion",
      }
    ],
  },
  //3 a b  c d e f
  {
    Title: "QUALIFYING CONDITIONS",
    subTitles: [
      {
        text: " The Affiliate hereby warrants that he/she:",
        subtext: [
          {
            text: 'Is of legal age in the applicable jurisdiction in order to agree to and to enter into the Agreement.'
          },
          {
            text: 'Is competent and duly authorized to enter into binding Agreements.'
          },
          {
            text: 'Is the proprietor of all rights, licenses, and permits to  market, promote and advertise Nabcasino.com in accordance with the provisions of the Agreement.'
          },
          {
            text: ' Will comply with all applicable rules, laws, and regulations in correlation with the promotion of Nabcasino.com.'
          },
          {
            text: 'Fully understands and accepts the terms of the Agreement.'
          }

        ]
      },

    ],
  },

  //4 a b  c d e f

  {
    Title: "RESPONSIBILITIES AND OBLIGATIONS OF THE COMPANY",
    subTitles: [
      {
        text: "The Company shall provide the Affiliate with all required information and marketing material for the implementation of the Tracking Link as the Company sees fit and needed. ",
      },
      {
        text: " The Company will assign a unique tracking identification code to all customers referred by the Affiliate. The Company shall calculate the Net Gaming Revenue generated via the tracking links, record the Net Gaming Revenue and the total amount of Affiliate commission earned via the link, provide the Affiliate with commission statistics, and handle all customer services related to its business.",
      },

      {
        text: " The Company shall pay the Affiliate the amount due depending on the commission scheme assigned to the affiliate on the traffic generated by the Affiliate subject to the terms of the Agreement. ",
      },

      {
        text: "  The Company reserves the right to freeze or close Affiliate Account(s). In addition to any other right, which the Company may be entitled to under this Agreement, the Company reserves the right to: ",
        subtext: [

          {
            text: " in case of having any reason to suspect that the Affiliate is in breach with the terms of the Agreement, the Affiliate´s Account(s) may be blocked, and payouts may be frozen for the period of investigation. If the investigation proves a violation of the Agreement to have taken place, Company reserves the right to withhold the Affiliate´s commission;  ",
          },


          {
            text: " close any Affiliate’s account(s) if in the sole opinion of the Company it is necessary to comply with our policy and/or to protect the interests of the website of the Company or the Company. If the Affiliate is in breach of the Agreement, Company may in addition to close the Affiliate’s account(s) take any other steps under the law to protect its interest; ",
          },


          {
            text: "  terminate the Agreement for Affiliate account(s) where affiliate threatens Partner.io staff with physical harm. Under no circumstances, the Company shall be held liable for eventual loss or damage caused to the Affiliate.",
          },

          {
            text: "  Company reserves the right to initiate an internal investigation of the Affiliate or player’s accounts on the Platform, in case the Company suspects the Affiliate in violation of the rules on the Affiliate website",
          },
        ]
      },


    ],
  },
  //5 a b  c d e f
  {
    Title: "RESPONSIBILITIES AND OBLIGATIONS OF THE AFFILIATE",
    subTitles: [
      {
        text: " The Affiliate hereby warrants:",

        subtext: [
          {
            text: " To use its best efforts to actively and effectively advertise, market and promote Platform(s) as widely as possible in order to maximize the benefit to the parties and to abide by the guidelines of the Company as they may be brought forward from time to time and/or as being published online.",
          },
          {
            text: " To market and refer potential players to Nabcasino.com at its own cost and expense. The Affiliate will be solely responsible for the distribution,content, and manners of its marketing activities. All of the Affiliate’s marketing activities must be professional, proper and lawful under applicable laws and must be in accordance with the Agreement.",
          },
          {
            text: " To use only the tracking link or bonus code provided within the scope of the Affiliate Program, otherwise, no guarantee whatsoever can be given for proper registration and sales accounting by the Company. Also, not to change or modify in any way any link or marketing material without prior written authorization from the Company. ",
          },
          {
            text: " To be responsible for the development, operation, and maintenance of its Affiliate website as well as for all material appearing on its Affiliate website. ",
          },
          {
            text: " That it will not perform any act which is libelous, discriminatory, obscene, unlawful or otherwise unsuitable or which contains sexually explicit, pornographic, obscene or graphically violent materials. ",
          },
          {
            text: " That it will not actively target any person who is under the legal age for gambling. ",
          },
          {
            text: " That it will not actively target any jurisdiction where gambling and the promotion thereof is illegal. ",
          },
          {
            text: " That it will not generate traffic to the Platform by illegal or fraudulent activity, particularly but not limited to: I. Sending spam. II. Incorrect meta tags. III. Registering as a player or making deposits directly or indirectly to any Affiliate Account through his/her tracker(s) for their own personal use and/or the use of its relatives, friends, employees or other third parties, or in any other way attempt to artificially increase the commission payable or to otherwise defraud the Company. Violation of this provision shall be deemed to be fraud. ",
          },
          {
            text: "That it will not present its Affiliate website in such a way that it might evoke any risk of confusion with the Company and or convey the impression that the Affiliate Website of the contracting party is partly or fully originated with the Company. This applies for any social media platform, including but not limited to Instagram, Facebook, Twitter, Telegram, TikTok, YouTube, Discord or any future social media platform. ",
          },

          {
            text: "  Without prejudice to the marketing material as may be forwarded by the Company and/or made available online through the Affiliate Program the Affiliate may not use the Platform or other terms, trademarks and other intellectual property rights that are vested in the Company unless the Company consents to such use in writing. ",
          },
          {
            text: " That it will not actively target any person who is under the legal age for gambling. ",
          },

          {
            text: " That it will not purchase any domains that include any names, words and phrases that are or can be deemed to form part of the Platform or bidding on any keywords or keyword phrases that include, any names, words and phrases that are or can be deemed to form part of the Platform in any format in any pay per click (PPC) search engine.  ",
          },
          {
            text: "  No Rakeback, Cashback, Incentive, or bonus programs to be run to customers without prior consent. ",
          },
        ]
      },

    ],
  },

  //6
  {
    Title: "AFFILIATE DORMANCY",
    subTitles: [
      {
        text: "An affiliate shall be considered dormant if they fail to generate any FTDs for a consecutive period of six (6) calendar months.",
      },
      {
        text: "In the event that an affiliate is classified as dormant, the affiliate’s commission structure shall be adjusted to a fixed revenue share rate of 5% from the active players they have previously referred. This rate shall be applicable until the affiliate becomes active again, as defined in point 6.3 below",
      },
      {
        text: "If the dormant affiliate generates at least one (1) FTD at any time after being classified as dormant, they shall be immediately reinstated as an active affiliate. Once the affiliate becomes active again, they will fall back into the commission scheme they were originally assigned to prior to being classified as dormant.",
      }
    ],
  },

  //7

  {
    Title: "SUB-AFFILIATES",
    subTitles: [
      {
        text: "Sub-Affiliate is an affiliate referred to the Company by you who holds the same qualifying conditions as an Affiliate. All such Sub-Affiliates will be linked to your Affiliate account (and you will be considered as the Master Affiliate) provided that the Sub-Affiliate(s) signs up using one of your Sub-Affiliate referral links. In order to become a Master Affiliate, the Affiliate must submit a request and get approval from the Company, a referral link is then provided to the Master Affiliate.",
      },
      {
        text: "In order to become a Sub-Affiliate, an affiliate must register to the Affiliate Program using the Sub-Affiliate referral link provided to them. You are solely responsible for ensuring the proper completion of a Sub-Affiliate’s registration. In the result of failure to complete any steps correctly, the Sub-Affiliate may not be linked to you.",
      },
      {
        text: "You will earn 5% of the Sub-Affiliate commission generated by each Sub-Affiliate you successfully refer to us. The Company reserves the right to change any of the commission fees at any given moment without prior notice. In case of a negative carryover by a Sub-Affiliate, the Master Affiliate will also inherit the negative carryover.",
      },
      {
        text: "The Company reserves the right, at its sole discretion, to investigate any Sub-Affiliate referred to us by you if we suspect any suspicious activity on the Sub-Affiliate account. If a Sub-Affiliate referred to us by you is found to have engaged in or been involved with Fraud, Spam, or is found to have breached any of the terms and conditions of the Agreement. The Company reserves at its sole discretion, the right to withhold all Sub-Affiliate commission payments relating to that Sub-Affiliate, due to you and to suspend, freeze and confiscate the account, if deemed appropriate.",
      },

      {
        text: "Any Sub-Affiliate will be bound by the terms and conditions of the Agreement and you will be liable to us for the performance of such Sub-Affiliate’s obligation under the Agreement.",
      },

      {
        text: "The Company holds no responsibility for any direct negotiations including disputes that happen between a Master Affiliate and a Sub-Affiliate. All responsibility regarding the communication between a Master Affiliate and a Sub-Affiliate remains with the Master Affiliate and the Sub-Affiliate",
      },
    ],
  },

  //8

  {
    Title: "PAYMENT",
    subTitles: [
      {
        text: "The Company agrees to pay the Affiliate a commission based on the Net Gaming Revenue generated from Real Money Players referred by the Affiliate website and/or another channel. The commission shall be deemed to be inclusive of value added tax or any other tax if applicable.",
      },
      {
        text: "The commission shall be a percentage of Net Gaming Revenue, or CPA, or a mixture of Net Gaming Revenue and CPA (as agreed upon between the Company and Affiliate). Agreement for the particular product as below or a CPA per qualified FTD.",
      },
      {
        text: "The commission is calculated at the end of each month and payments shall be performed by the 10th working day of each calendar month, provided that the amount due exceeds 100 USDT. If the balance due is less than the minimum threshold, it shall be carried over to the following month and shall be payable when it collectively exceeds the minimum threshold.",
      },
      {
        text: "The commission payment shall be made only if the outstanding Affiliate balance is positive. If the balance is negative due to the user’s performance on games the balance shall not be carried over to the next month. This method is referred to as no negative carryover. ",
      },

      {
        text: "Payment of commissions shall be made to Affiliate in their desired currency. If an error is made in calculating the commission, the Company reserves the right to correct such a calculation at any time and will pay out underpayment or reclaim overpayment made to the Affiliate by the 15th working day of the calendar month. If the payment has not been made to the Affiliate by the 15th working day of the calendar month, the commission will be paid out in full during the next month only when the Affiliate has provided all necessary details to the Company. ",
      },

      {
        text: "Acceptance of payment by the Affiliate shall be deemed to be full and final settlement of the balance due for the period indicated.",
      },
      {
        text: "If the Affiliate disagrees with the balance due as reported, it shall within a period of seven (7) days, send an email to the Company to affiliates@nabaffiliates.com and indicate the reasons for the dispute.",
      },
      {
        text: "The Company may delay payment of any balance to the Affiliate for up to sixty (60) days, while it investigates and verifies that the relevant transactions comply with the provisions of the terms of the Agreement.",
      },
      {
        text: "No payment shall be due when the traffic generated is illegal or contravenes any provision of the terms of the Agreement ",
      },

      {
        text: "The Affiliate agrees to return all commissions received based on fraudulent or falsified transactions, plus all costs for legal causes or actions that may be brought against the Affiliate to the fullest extent of the law.",
      },

      {
        text: " For the sake of clarity, the parties specifically agree that upon the termination of this Agreement by either party, the Affiliate shall no longer be entitled to receive any payment whatsoever from the Company, provided that payments already due (earned and unpaid commissions) shall be paid out.",
      },
      {
        text: "At the sole discretion of the Company, the Affiliate may be given the opportunity to restructure its commission structure. Examples of alternative revenue sources could include a Cost Per Acquisition (CPA) module. The Company hereby states to the Affiliate that only one type of revenue structure may be applied, and it shall not be possible for two different revenue structures to co-exist. Therefore, once an Affiliate opts to accept the Company’s offer for a different revenue structure, then the Affiliate is aware and hereby agrees and understands that the new proposed revenue structure shall replace his/her existing commission structure in its entirety. Notwithstanding the above, all the Affiliate’s obligations assumed under the Agreement will still continue to apply to the Affiliate up until the termination of the Agreement and thereafter, in accordance with the terms contained in this Agreement. ",
      },
      {
        text: "The Affiliate shall be exclusively responsible for the payment of any and all taxes, levies, fees, charges and any other money payable or due both locally and abroad (if any) to any tax authority, department or other competent entity by the Affiliate as a result of the commission generated under this Agreement. The Company shall in no manner whatsoever be held liable for any amounts unpaid but found to be due by the Affiliate and the Affiliate hereby indemnifies the Company in that regard.",
      },


    ],
  },

  //9 


  {
    Title: "TERMINATION",
    subTitles: [
      {
        text: "This Agreement may be terminated by either party by giving a thirty (30) day written notification to the other party. Written notification may be given by email to affiliates@nabaffiliates.com.",
      },
      {
        text: "The contracting parties hereby agree that upon the termination of the Agreement: ",
      },
      {
        text: "If the dormant affiliate generates at least one (1) FTD at any time after being classified as dormant, they shall be immediately reinstated as an active affiliate. Once the affiliate becomes active again, they will fall back into the commission scheme they were originally assigned to prior to being classified as dormant.",
      },



      {
        text: "The Affiliate must remove all references to the Platform from the Affiliate website and/or other marketing channels and communications, irrespective of whether the communications are commercial or non-commercial.",
      },
      {
        text: "All rights and licenses granted to the Affiliate under the Agreement shall immediately terminate and all rights shall revert to the respective licensors, and the Affiliate will cease the use of any trademarks, service marks, logos and other designations vested in the Company.  ",
      },
      {
        text: "The Affiliate will be entitled only to those earned and unpaid commissions as of the effective date of termination; however, provided the Company may withhold the Affiliate’s final payment for a reasonable time to ensure that the correct amount is paid. The Affiliate will not be eligible to earn or receive commissions after this termination date.",
      },

      {
        text: " If the Agreement is terminated by the Company on the basis of the Affiliate’s breach of the clause 4.4.(b), the Company shall be entitled to withhold the Affiliate’s earned but unpaid commissions as of the termination date as collateral for any claim arising from such a breach. It is further specified that termination by the Company due to a breach by the Affiliate of any of the clauses in the Agreement shall not require a notice period and such termination shall have an immediate effect upon simple notification by the Company to the Affiliate. ",
      },
      {
        text: "The Affiliate must return original data items that hold any and all confidential information (and all copies and derivations thereof) in the Affiliate’s possession, custody, and control to the Company or delete permanently any copies or electronic form data containers that contain the same. ",
      },
      {
        text: "The Affiliate will release the Company from all obligations and liabilities occurring or arising after the date of such termination, except with respect to those obligations that by their nature are designed to survive termination. Termination will not relieve the Affiliate from any liability arising from any breach of the Agreement, which occurred prior to termination and/or to any liability arising from any breach of confidential information even if the breach arises at any time following the termination of the Agreement. The Affiliate’s obligation of confidentiality towards the Company shall survive the termination of this Agreement.",
      }
    ],
  },


  //10

  {
    Title: "WARRANTIES",
    subTitles: [
      {
        text: "The Affiliate expressly acknowledges and agrees that the use of the internet is at its own risk and that this Affiliate Program is provided “as is” and “as available” without any warranties or conditions whatsoever, even if expressed or implied. No guarantee is made that it will make access to its Affiliate website possible at any particular time or any particular location. ",
      },
      {
        text: "The Company shall in no event be liable to the Affiliate or anyone else for any inaccuracy, error or omission in, or loss, injury or damage caused in whole or in part by failures, delays or interruptions of the Platforms’ website or the Affiliate Program.",
      },

    ],
  },


  //11 

  {
    Title: "INDEMNIFICATION",
    subTitles: [
      {
        text: "The Affiliate agrees to defend, indemnify and hold the Company and its affiliates, successors, officers, employees, agents, directors, shareholders, and attorneys, free and harmless from and against any and all claims and liabilities, including reasonable attorneys’ and experts’ fees, related to or arising from: a) Any breach of the Affiliate’s representations, warranties or covenants under the Agreement. b) The Affiliate’s use (or misuse) of the marketing materials. c) All conduct and activities occurring under the Affiliate’s user ID and password. d) Any defamatory, libellous or illegal material contained within the Affiliate website or as part of the Affiliate’s information and data. e) Any claim or contention that the Affiliate website or the Affiliate’s information and data infringe any third party’s patent, copyright, trademark, or other intellectual property rights or violate any third party’s rights of privacy or publicity. f) Third party access or use of the Affiliate website or to the Affiliate’s information and data. g) Any claim related to the Affiliate website. h) Any violation of the Agreement. ",
      },
      {
        text: "The Company reserves the right to participate, at its own expense in the defense of any matter.",
      },

    ],
  },


  //12 

  {
    Title: "COMPANY’S RIGHTS",
    subTitles: [
      {
        text: " The Company may refuse any player or close a player’s account if it is necessary to comply with the Company’s policy and/or protect the interest of the Company. ",
      },
      {
        text: "The Company may refuse any applicant and/or may close any Affiliate’s Account if it is necessary to comply with the law, Company’s policy, General Terms and Conditions or Privacy Policy, the Agreement and/or protect the interest of the Company. If the Affiliate is in breach of the Agreement or the Company’s terms or other rules, policies, and guidelines, the Company may in addition to closing the Affiliate Account take any other steps in law to protect its interests",
      },

    ],
  },


  //13


  {
    Title: "GOVERNING LAW & JURISDICTIONS",
    subTitles: [
      {
        text: "  This Agreement shall be governed and construed in accordance with the laws of Curacao and any action or dispute relating to the terms of this Agreement must be brought in Curacao and the Affiliate irrevocably consents to the jurisdiction of the Curacao law courts. ",
      },
      {
        text: "Any claim, dispute or matter arising from or in connection to Platform(s) website shall be governed and construed in accordance with the laws of Curacao and any action relating to the Platforms website must be brought in Curacao and the Affiliate irrevocably consents to the jurisdiction of the Curacao law courts.",
      },

    ],
  },


  //14 

  {
    Title: "ASSIGNMENT",
    subTitles: [
      {
        text: "  The Affiliate may not assign the Agreement, by restructuring or otherwise, without obtaining the prior written consent of the Company. In the event that the Affiliate acquires or otherwise obtains control of another Affiliate of Platform(s), then Affiliate Accounts will co-exist on individual terms. ",
      },
      {
        text: "The Company may assign the Agreement, by restructuring or otherwise, at any time without obtaining the prior consent of the Affiliate",
      },


      {
        text: "  The Company recognizes that an Affiliate may wish to sell his/ her/ its Affiliate business to a third party. The Company requires an Affiliate to recognize and respect that the personal qualities, probity, and background of the Company’s affiliates is vital to the Company’s decision to accept a person as an Affiliate of the Affiliate Program.",
      },
      {
        text: "If an Affiliate wishes to sell or otherwise dispose of the shares or assets of his/ her/ its Affiliate business to a third party (or conclude any transaction of a similar nature with a third party that will result in an effective change in control of his/ her/ its Affiliate business) the Affiliate shall be required, prior to completing the sale, disposal, or transfer, to: 14.4.1 give the Company no less than 30 (thirty) days prior written notice of such intention, simultaneously provide such details as the Company may request (which shall include, but not be limited to, the selling Affiliate’s Affiliate ID and full details of the intended purchaser (including their banking details and, if they are already an Affiliate of the Affiliate Program, their Affiliate ID) and furnish the Company with an irrevocable consent and authority to pay the selling Affiliates Commission, after the sale is completed, to the purchaser; 14.4.2 make the deed of sale subject to the suspense condition that the Company approve such purchaser as an Affiliate of the Affiliate Program and that such intended purchaser shall, subject to the Company’s approval (at the Company’s sole discretion) join the Affiliate Program. 14.5 In any event, the Company reserves the right, at its sole discretion, to reject an intended purchaser of the Affiliate’s business, as an affiliate of the Affiliate Program, and in this event may terminate this Agreement immediately. 14.6 In the event that the Affiliate passes away, this Agreement will immediately terminate.",
      },


    ],
  },

  //15
  {
    Title: "NON-WAIVER",
    subTitles: [
      {
        text: "  The Company’s failure to enforce the Affiliate’s adherence to the terms of the Agreement shall not constitute a waiver of the right of the Company to enforce said terms at any time. ",
      },
    ]
  },



  //16
  {
    Title: "FORCE MAJEURE",
    subTitles: [
      {
        text: "   Neither party shall be liable to the other for any delay or failure to perform its obligations under the Agreement if such delay or failure arises from a cause beyond the reasonable control of and is not the fault of such party, including but not limited to labour disputes, strikes, industrial disturbances, acts of God, acts of terrorism, floods, lightning, utility or communications failures, earthquakes or other casualties. If a force majeure event occurs, the non-performing party is excused from whatever performance is prevented by the force majeure event to the extent prevented. Provided that, if the force majeure event subsists for a period exceeding thirty (30) days then either party may terminate the Agreement without notice. ",
      },
    ]
  },

  //17
  {
    Title: "RELATIONSHIP OF THE PARTIES",
    subTitles: [
      {
        text: "   Nothing contained in the Agreement, nor any action taken by any party to the Agreement, shall be deemed to constitute either party (or any of such party’s employees, agents, or representatives) an employee, or legal representative of the other party, nor to create any partnership, joint venture, association, or syndication among or between the parties, nor to confer on either party any express or implied right, power or authority to enter into any agreement or commitment on behalf of (nor to impose any obligation upon) the other party. ",
      },
    ]
  },
  //18
  {
    Title: "SEVERABILITY/WAIVER",
    subTitles: [
      {
        text: "   Whenever possible, each provision of the Agreement shall be interpreted in such a manner as to be effective and valid under applicable law, but if any provision of the Agreement is held to be invalid, illegal or unenforceable in any respect, such provision will be ineffective only to the extent of such invalidity, or unenforceability, without invalidating the remainder of the Agreement. No waiver will be implied from conduct or failure to enforce any rights and must be in writing to be effective. ",
      },
    ]
  },

  //19

  {
    "Title": "CONFIDENTIALITY",
    "subTitles": [
      {
        "text": "All information, including but not limited to business and financial, lists of customers and buyers, as well as price and sales information and any information relating to products, records, operations, business plans, processes, product information, business know-how or logic, trade secrets, market opportunities, Affiliate Network, Affiliate Program, Sub-Affiliates and personal data of the players of the Company shall be treated confidentially. Such information must not be used for own commercial or other purposes or divulged to any person or third party neither directly nor indirectly unless prior explicit and written consent has been provided by the Company. This provision shall survive the termination of the Agreement."
      },
      {
        "text": "The Affiliate obliges himself/herself not to use the confidential information for any purpose other than the performance of its obligations under the Agreement."
      }
    ]
  },


  //20


  {
    Title: "CHANGES TO THIS AGREEMENT",
    subTitles: [
      {
        text: "  The Company reserves the right to amend, alter, delete or add to any of the provisions of the Agreement, at any time and at its sole discretion, without giving any advance notice to the Affiliate subject to the terms set out in the Agreement. ",

      },

      {
        text: "   In case of any discrepancy between the meanings of any translated versions of the Agreement, the English language version shall prevail ",
      },
    ]
  },


  //21

  {
    Title: "TRADEMARKS",
    subTitles: [
      {
        text: "  Nothing contained in the Agreement will grant either party any right, title or interest in the trademarks, trade names, service marks or other intellectual property rights (referred as “Marks”) of the other party. At no time during or after the term will either party attempt or challenge or assist or allow others to challenge or to register or to attempt to register the Marks of the other party or of any company within the group of companies of the other party. Provided also that neither of the parties will register nor attempt to register any Mark which is basically similar to and/or confusingly similar to any Mark which belongs to the other party or to any company contained within the other party’s group of companies.",

      },


    ]
  },


  //22
  {
    Title: "COMMISSION STRUCTURE",
    subTitles: [
      {
        text: " Commission structure to be agreed upon by the Company and Affiliate.",

      },

    ]
  },

  //23
  {
    Title: "Testimonials",
    subTitles: [
      {
        text: " See how our affiliates are succeding with us!",

      },

    ]
  },


];


export const subTitlesAfterAff = [



  {
    text: "  This document (the Affiliate Agreement or Agreement) sets out the terms and conditions agreed between:",
  },
  {
    title: " New Age N.V",
    text: "(hereinafter Company), a company duly registered under the laws of Curacao and the person/company set out on the relevant application form (hereinafter the “Affiliate”), enabling the Affiliate to join and become a member (if the Affiliate’s application is successful) of the Platform(s) Affiliate Program (“Program”).",
  },
  {
    text: "By completing the Platforms Affiliate Program application and clicking “I agree to the Terms and Conditions” within the registration form, you hereby agree to abide by all the terms and conditions set out in Platforms site.",
  },
  {
    text: "The agreed commission structure for the platforms which is to be determined by negotiation between the Company and Affiliate shall be deemed to form an integral part of this agreement. You hereby agree to:",
  },
  {
    text: "1. Participate in the Affiliate Program",
  },
  {
    text: "2. Use of the Platforms website and/or marketing tools.",
  },
  {
    text: "3. The condition that the acceptance of any Affiliate commissions from Platform) website confirms your irrevocable acceptance of this Agreement and any modifications thereto",
  },
  {
    text: "4. not to allow the targeting of any jurisdiction where gambling and/or the promotion thereof is prohibited.",
  },
  {
    text: "Therefore, you shall be obliged to continuously comply with the terms of Nabcasino.com as well as to comply with the General Terms and Conditions and Privacy Policy of Nabcasino.com, as well as with any other rules and/or guidelines brought forward from time to time. The Agreement between the Company and you enters into effect on the date the Affiliate Program application is approved by us.",
  },
];


export const testimonials = [
  {
    rating: 5,

    description: 'description1',
    link: 'https://www.ptptips.com.au/',
    name: 'ptptips',
    img: oldPTPLogo
  },
  {
    rating: 5,

    description: 'description3',
    link: "https://www.thegamblerbay.com/",
    name: 'thegamblerbay',
    img: thegamblerbay
  },
  {
    rating: 5,
    description: 'description2',
    link: "https://betpokies.co.nz/",
    name: 'betpokies',
    img: casinoGuru
  },
  {
    rating: 5,
    description: 'description4',
    link: 'https://cryptogamblers.co/bonuses/vip-casinos/',
    name: 'cryptogamblers',
    img: cyrptogamblers
  },
  {
    rating: 5,
    description: 'description5',
    link: 'https://tips.gg/',
    name: 'TipsGG',
    img: tipgg
  },
  {
    rating: 5,
    description: 'description6',
    link: 'https://sloteire.com/',
    name: 'sloteire.com',
    img: sloteire
  }
  ,
  {
    rating: 5,
    description: 'description7',
    link: 'https://www.spicycasinos.com/',
    name: 'spicycasinos',
    img: Spicy
  },

  {
    rating: 5,
    description: 'description8',
    link: 'https://casinomentor.com/reviews/nab-casino',
    name: 'casinomentor',
    img: casinomentor
  },

  {
    rating: 5,
    description: 'description9',
    link: 'https://www.allgemcasinos.com/ ',
    name: 'allgemcasinos',
    img: siteLogo
  },


  {
    rating: 5,
    description: 'description10',
    link: 'https://toprevolutcasinos.com/ ',
    name: 'toprevolutcasinos',
    img: topcasino
  },


  {
    rating: 5,
    description: 'description11',
    link: 'https://freespinsnodepositoffers.co.uk/ ',
    name: 'freespinsnodepositoffers',
  },

  {
    rating: 5,
    description: 'description12',
    link: 'https://onlinecasinohunters.com/casino/nab-casino/ ',
    name: 'onlinecasinohunters',
    img:casinoHuner
  },

  {
    rating: 5,
    description: 'description13',
    link: 'https://slotbonusinsider.com ',
    name: 'slotbonusinsider',
    img:slotbonusinsider
  },

  {
    rating: 5,
    description: 'description14',
    link: 'https://www.slotpicker.app/en/casinos/nab-casino',
    name: 'Slotpicker',
    img:Slotpicker
  },

  {
    rating: 5,
    description: 'description15',
    link: 'https://FreeSpinsGratis.com',
    name: 'FreeSpinsGratis',
    img: FreeSpinsGratis
  },
  {
    rating: 5,
    description: 'description16',
    link: 'https://www.FreeSpinsGames.com',
    name: 'FreeSpinsGames',
    img: FreeSpinsGames
  },
  {
    rating: 5,
    description: 'description17',
    link: 'https://MicrogamingSpins.com',
    name: 'MicrogamingSpins',
    img: Microgaming
  },
  {
    rating: 5,
    description: 'description18',
    link: 'https://www.FreeSpinsMobileCasino.com',
    name: 'FreeSpinsMobileCasino',
    img: FreeSpinsMobileCasino
  },
  {
    rating: 5,
    description: 'description19',
    link: 'https://FreeSpinsBet.com',
    name: 'FreeSpinsBet',
    img: FreeSpinsBet
  },
  {
    rating: 5,
    description: 'description20',
    link: 'https://www.FreeSpinsMicrogamingCasino.com',
    name: 'FreeSpinsMicrogamingCasino',
    img: FreeSpinsMicro
  },
  {
    rating: 5,
    description: 'description21',
    link: 'https://www.FreeSpinsBonus.net',
    name: 'FreeSpinsBonus',
    img: FreeSpinsBonus
  },
  {
    rating: 5,
    description: 'description22',
    link: 'https://www.FreeSpinsInfo.com',
    name: 'FreeSpinsInfo',
    img: FreeSpinsInfo
  },
  {
    rating: 5,
    description: 'description23',
    link: 'https://FreeSpins1.com',
    name: 'FreeSpins1',
    img: FreeSpins1
  },
  {
    rating: 5,
    description: 'description24',
    link: 'https://FreeSpinMicrogaming.com',
    name: 'FreeSpinMicrogaming',
    img: FreeSpinsMicroGaming
  },
  {
    rating: 5,
    description: 'description25',
    link: 'https://www.FreeSpinsNetentCasino.com',
    name: 'FreeSpinsNetentCasino',
    img: FreeSpinsNetentCasino
  },
  {
    rating: 5,
    description: 'description26',
    link: 'https://www.FreeSpinsNetent.com',
    name: 'FreeSpinsNetent',
    img: FreeSpinsNetent
  },
  {
    rating: 5,
    description: 'description27',
    link: 'https://www.freespinsmicrogaming.com',
    name: 'freespinsmicrogaming',
    img: freespinsmicrogamingHat
  },
  {
    rating: 5,
    description: 'description28',
    link: 'https://www.Freispiele.org',
    name: 'Freispiele',
    img: Freispiele
  },

  {
    rating: 5,
    description: 'description29',
    link: 'https://FreeSpinsGaming.com',
    name: 'FreeSpinsGaming',
    img: FreeSpinsGaming
  },

  {
    rating: 5,
    description: 'description30',
    link: 'https://Casinogy.com',
    name: 'Casinogy',
    img: Casinogy
  },
  {
    rating: 5,
    description: 'description31',
    link: 'https://casinobonuschips.com',
    name: 'casinobonuschips',
    img: casinobonuschip
  }
  
  ,{
    rating: 5,
    description: 'description32',
    link: 'https://www.bonuswanted.com/',
    name: 'bonuswanted',
    img: Bonuswanted
  },

  
  {
    rating: 5,
    description: 'description33',
    link: 'https://slotmasters1k.net/',
    name: 'slotmasters1k',
    img: Masters
  },

  {
    rating: 5,
    description: 'description34',
    link: 'https://www.casino-groups.com/',
    name: 'casino-groups',
    img: CasinoGroups
  },

  {
    rating: 5,
    description: 'description35',
    link: 'https://Chipy.com/',
    name: 'Chipy',
    img: Chipy
  },     
   
   {
    rating: 5,
    description: 'description36',
    link: 'https://SlotsMate.com/',
    name: 'SlotsMate',
    img: Slotsmate
  },

  {
    rating: 5,
    description: 'description37',
    link: 'https://NonStopBonus.com/',
    name: 'NonStopBonus',
    img: Nonstop
  },

  {
    rating: 5,
    description: 'description38',
    link: 'https://CasinoFreak.com/',
    name: 'CasinoFreak',
    img: Casinofreak
  },

  {
    rating: 5,
    description: 'description39',
    link: 'https://GamblersLab.com/',
    name: 'GamblersLab',
    img: Gamblerslab
  },

  {
    rating: 5,
    description: 'description40',
    link: 'https://jkcp.com/',
    name: 'jkcp',
    img: Jkcp
  },

  {
    rating: 5,
    description: 'description41',
    link: ' https://lucklandia.com/',
    name: 'lucklandia',
    img: LuckLandia
  },

  {
    rating: 5,
    description: 'description42',
    link: 'https://www.infocasino.es/',
    name: 'infocasino',
    img: logotipo
  },


  {
    rating: 5,
    description: 'description43',
    link: 'https://www.kasinopelaajat.com/',
    name: 'Kasinopelaajat',
    img: kasinopelaajat
  },

  {
    rating: 5,
    description: 'description44',
    link: 'https://www.gamblescope.com',
    name: 'gamblescope',
    img: "https://gamblescope.com/static/images/kit/gamblescope.png"
  },

]








export const Events = [
  {
    href: "https://www.igblive.com/",
    imageSrc: iGB_live,
    imageAlt: "iGB Live",
    logoSrc: iGB_liveL,
    logoAlt: "iGB Live Logo",
    title: "iGB Live!",
    location: "RAI Amsterdam",
    date: "16-19 Jul 2024"
  },
  {
    href: "https://sbcevents.com/sbc-summit/",
    imageSrc: SBC_Summit,
    imageAlt: "SBC Summit",
    logoSrc: SBC_SummitL,
    logoAlt: "SBC Summit Logo",
    title: "SBC Summit",
    location: "Portugal",
    date: "24-26 Sep 2024"
  },
  {
    href: "https://sigma.world/europe/",
    imageSrc: SIGMA_Europe_Malta,
    imageAlt: "SIGMA Europe Malta",
    logoSrc: Sigma_EU,
    logoAlt: "Sigma EU Logo",
    title: "SIGMA Europe Malta",
    location: "World's Gaming Festival",
    date: "Feb 2024"
  },
  {
    href: "https://www.icegaming.com/ice-2025",
    imageSrc: ICE_Barcelona_25,
    imageAlt: "ICE Barcelona 25",
    logoSrc: ICE,
    logoAlt: "ICE Logo",
    title: "ICE Barcelona",
    location: "Fira Barcelona Gran Via",
    date: "Jan 2025"
  },
  {
    href: "https://sigma.world/eurasia/",
    imageSrc: SIGMA_Eurasia,
    imageAlt: "SIGMA Eurasia",
    logoSrc: Sigma_EU,
    logoAlt: "Sigma EU Logo",
    title: "SIGMA Eurasia",
    location: "Dubai",
    date: "11-14 Nov 2024"
  }
];

